var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "carousel-style" }, [
    _c(
      "div",
      {
        class: _vm.carouselWrapClass,
        style: _vm.imgWrapStyle,
        on: {
          mouseover: _vm.photoMouseover,
          mouseleave: _vm.photoMouseleave,
          touchstart: _vm.photoMouseover,
          touchend: _vm.photoMouseleave,
        },
      },
      [
        _c(
          "ul",
          { staticClass: "photo_list" },
          _vm._l(_vm.filterNewsList, function (item, index) {
            return _c(
              "li",
              {
                key: item.id,
                class: _vm.photoItemClass(index),
                style: _vm.photoListItemStyle,
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "hovermenu",
                        rawName: "v-hovermenu",
                        value: { hovermenu: _vm.hovermenu, args: item.id },
                        expression: "{ hovermenu, args: item.id }",
                      },
                    ],
                    staticClass: "photoItemWrap",
                    on: {
                      mouseenter: function ($event) {
                        return _vm.handleItemMouseEnter(item)
                      },
                      mouseleave: function ($event) {
                        return _vm.handleItemMouseLeave(item)
                      },
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          target: _vm.target,
                          href: _vm.setQueryString(
                            item.url,
                            "fromColId",
                            _vm.colId,
                            item.flag
                          ),
                          onclick: _vm.onclickStr(item),
                        },
                      },
                      [
                        _c("ImageComponent", {
                          directives: [
                            {
                              name: "lazyload2",
                              rawName: "v-lazyload2",
                              value: {
                                id: _vm.moduleId,
                                src: item.imgPath,
                                carousel: true,
                              },
                              expression:
                                "{\n                                id: moduleId,\n                                src: item.imgPath,\n                                carousel: true,\n                            }",
                            },
                          ],
                          staticClass: "photo_item",
                          style: _vm.imgStyle,
                          attrs: {
                            src: item.imgPath,
                            "img-effects": _vm.imgEffects,
                          },
                        }),
                        _vm._v(" "),
                        _vm.showTitle
                          ? _c("div", { staticClass: "title" }, [
                              _c("span", {
                                style: {
                                  ..._vm.titleStyle,
                                  ..._vm.getTitleHoverColor(item),
                                },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.decodeHtml(item.title)
                                  ),
                                },
                              }),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm.filterNewsList.length && !_vm.isMobi
          ? [
              _c(
                "div",
                { staticClass: "circle pre", on: { click: _vm.prev } },
                [
                  _c("svg", { staticClass: "arrow" }, [
                    _c("use", { attrs: { "xlink:href": "#icon_prev" } }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "circle next", on: { click: _vm.next } },
                [
                  _c("svg", { staticClass: "arrow" }, [
                    _c("use", { attrs: { "xlink:href": "#icon_next" } }),
                  ]),
                ]
              ),
            ]
          : _vm._e(),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "dot_list" },
          _vm._l(_vm.filterNewsList, function (item, index) {
            return _c("li", {
              key: "dot_" + index,
              staticClass: "dot_item",
              class: { active_dot: _vm.currentPicIndex == index },
              on: {
                click: function ($event) {
                  return _vm.selectPhoto(index)
                },
              },
            })
          }),
          0
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }